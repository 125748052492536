/* eslint-disable no-unused-vars */
import { useEffect, useMemo, lazy, useState, Suspense, useCallback } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'use-http';
import { useForm, FormProvider } from 'react-hook-form';
import { TableProvider, useTableProvider } from 'contexts/TableProvider';
import { useDispatch } from 'react-redux';
import { QuickViewCore } from "contexts/QuickViewProvider";
import { useBee } from 'contexts/Bee';
import { ServizioCore, useServizio } from 'contexts/ServizioContext';
import moment from 'moment';

//component
import PageHeader from 'components/PageHeader';

//sections
const DettagliAttivita = lazy(() => import('./DettagliAttivita'));
const ListaOperatori = lazy(() => import('./ListaOperatori'));
const OperatoriAssegnati = lazy(() => import('./OperatoriAssegnati'));

//project import
import { openSnackbar, closeSnackbar } from 'store/reducers/snackbar';

//MUI
import
{
   Skeleton,
   Stack,
   Typography
} from '@mui/material'

const AssegnazioneAvanzataMarkup = () =>
{
   //recupero id operatore dai parametri url
   const {
      idContratto,
      idServizio,
      idGiorno,
      idTurno
   } = useParams();

   const {
      servizio,
      giorno,
      turni,
      turno,
      requesting
   } = useServizio();

   //imposto l'oggetto con i links pre la breadcrumbs
   const defaultBreadcrumbs = useMemo(() =>
   {
      return [
         {
            label: "Pianificazione",
         },
         {
            label: "Contratti",
            path: "/pianificazione/contratti"
         },
         {
            label: `Contratto #${idContratto}`,
            path: `/pianificazione/contratti/${idContratto}/servizi/`
         },
         {
            label: "Servizio",
            path: `/pianificazione/contratti/${idContratto}/servizi/${idServizio}/assegnazione-risorse`
         },
         {
            label: "Assegnazione avanzata"
         }
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [idContratto, idServizio]);

   return (
      <>
         <PageHeader
            title="Assegnazione avanzata"
            breadcrumbs={defaultBreadcrumbs}
            afterTitle={requesting ? <Skeleton width={230} /> : (
               <Typography
                  variant="body"
               >
                  <Typography
                     component="span"
                     variant="body"
                     sx={{
                        color: 'text.secondary',
                        fontWeight: 700
                     }}
                  >
                     {`${servizio?.contratto?.cliente?.ragione_sociale.replace(/"/g, ' ')} - ${servizio?.contratto?.titolo}`}
                  </Typography>

                  {giorno && ` - ${moment(giorno?.giorno, 'YYYY-MM-DD').format('DD/MM/YYYY')}`}

                  {idTurno && turno && ` - turno ${moment(turno?.inizio_f1, 'HH:mm:ss').format('HH:mm')}/${moment(turno?.fine_f1, 'HH:mm:ss').format('HH:mm')}${turno?.inizio_f2 && turno?.fine_f2 ? ` - ${moment(turno?.inizio_f2, 'HH:mm:ss').format('HH:mm')}/${moment(turno?.fine_f2, 'HH:mm:ss').format('HH:mm')}` : ''}`}

               </Typography>
            )}
         />
         <QuickViewCore>

            <Stack spacing={3}>

               <Suspense fallback={''}>
                  <DettagliAttivita />
               </Suspense>

               {
                  idTurno &&
                  <Suspense fallback={''}>
                     <ListaOperatori />
                  </Suspense>
               }

               <Suspense fallback={''}>
                  <OperatoriAssegnati />
               </Suspense>
            </Stack>

         </QuickViewCore>
      </>
   )
}

const AssegnazioneAvanzata = () =>
{
   const {
      idContratto,
      idServizio,
      idGiorno,
      idTurno
   } = useParams();

   return (
      <ServizioCore>
         <AssegnazioneAvanzataMarkup />
      </ServizioCore>
   )
}

export default AssegnazioneAvanzata;