import PropTypes from 'prop-types';
import { useContext, createContext, useState, useEffect, useReducer, useCallback } from 'react';
import { useFetch } from 'use-http';
import { useDispatch } from 'react-redux';
import { useBee } from './Bee';

//components
import { openSnackbar } from 'store/reducers/snackbar';

const tableContext = createContext();

export function useTableProvider()
{
   return useContext(tableContext);
}

export function TableProvider(props)
{
   const {
      children,
      defaultRowsPerPage = 25,
      defaultSortField = null,
      defaultSortOrder = true,
      defaultCustomQuery = {},
      defaultGroupBy = [],
      path = '',
      baseUrl = process.env.REACT_APP_BASE_API_URL,
      defaultAutoload = true,
      otherProps
   } = props;

   const {
      request,
      response,
      loading,
      error
   } = useFetch(baseUrl, { cachePolicy: 'no-cache' });

   const {
      reload: globalReload
   } = useBee();

   const dispatcher = useDispatch();

   const [data, setData] = useState([]);
   const [isFirstRender, setIsFirstRender] = useState(true);
   const [reload, setReload] = useState(null);

   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
   const [isSearch, setIsSearch] = useState();
   // eslint-disable-next-line no-unused-vars
   const [customQuery, setCustomQuery] = useState(defaultCustomQuery);
   const [sortField, setSortField] = useState(defaultSortField);
   const [sortOrder, setSortOrder] = useState(defaultSortOrder);
   const [groupBy, setGroupBy] = useState(defaultGroupBy);
   const [totalCount, setTotalCount] = useState(0);
   const [autoload, setAutoload] = useState(defaultAutoload);
   // eslint-disable-next-line no-unused-vars
   const [lastQuery, setLastQuery] = useState({});

   const initialState = {
      trigger: 1,
      pageParam: 1,
      rowsPerPageParam: defaultRowsPerPage,
      searchByParam: "",
      sortParam: { field: defaultSortField, order: defaultSortOrder },
      groupByParam: defaultGroupBy,
      customQueryParam: defaultCustomQuery
   };

   const PAGE_CHANGED = 'PAGE_CHANGED';
   const ROWS_PER_PAGE_CHANGED = 'ROWS_PER_PAGE_CHANGED';
   const SORT_CHANGED = 'SORT_CHANGED';
   const SEARCHBY_CHANGED = 'SEARCHBY_CHANGED';
   const GROUPBY_CHANGED = 'GROUPBY_CHANGED';
   const CUSTOM_QUERY_CHANGED = 'CUSTOM_QUERY_CHANGED';
   const TRIGGER_CHANGED = 'TRIGGER_CHANGED';
   //const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED'

   const reducer = (state, { type, payload }) =>
   {
      switch (type)
      {
         case PAGE_CHANGED:
            return {
               ...state,
               pageParam: payload,
            };
         case ROWS_PER_PAGE_CHANGED:
            return {
               ...state,
               rowsPerPageParam: payload,
            };
         case SORT_CHANGED:
            return {
               ...state,
               sortParam: payload
            };
         case GROUPBY_CHANGED:
            return {
               ...state,
               groupByParam: payload
            };
         case SEARCHBY_CHANGED:
            return {
               ...state,
               searchByParam: payload
            };
         case CUSTOM_QUERY_CHANGED:
            return {
               ...state,
               customQueryParam: payload
            };
         case TRIGGER_CHANGED:
            return {
               ...state,
               trigger: payload
            };
         // case TOTAL_COUNT_CHANGED:
         //    return {
         //       ...state,
         //       totalCountParam: payload,
         //    };
         default:
            throw new Error(`Unhandled action type: ${type}`)
      }
   };

   const [{ pageParam, rowsPerPageParam, searchByParam, sortParam, groupByParam, customQueryParam, trigger }, dispatch] = useReducer(reducer, initialState);

   useEffect(() =>
   {

      if (error)
      {
         setData([]);
         setPage(0);
         setTotalCount(0);

         dispatcher(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: error.message,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [error]);

   useEffect(() =>
   {

      const fetchData = async () =>
      {

         try
         {

            let params = {
               page: pageParam,
               page_size: rowsPerPageParam
            };

            if (searchByParam.length > 0)
            {
               params.global = searchByParam;
            } else
            {
               delete customQueryParam.global;
            }

            if (sortParam)
            {
               if (sortParam.field)
               {
                  params.order_field = sortParam.field;
               }
               params.order_asc = sortParam.order;
            }

            if (groupByParam.length > 0)
            {
               params.group_by = groupByParam;
            }else{
               delete customQueryParam.group_by;
            }

            if (Object.keys(customQueryParam).length > 0)
            {
               params = Object.assign(customQueryParam, params);
            }

            const queryString = new URLSearchParams(params).toString();

            const res = await request.get(`${path}?${queryString}`);

            // if (error)
            // {
            //    return;
            // }

            console.log('response', response);
            console.log('RES', res);

            setLastQuery(params);

            if (response.ok)
            {
               setData((prevState) =>
               {
                  console.log(prevState);
                  return res;
               });
               setPage(res?.page - 1);
               setTotalCount(res?.count);
            }

            setIsFirstRender(false);

         } catch (e)
         {
            console.log(e.message);
         }
      }

      if (autoload)
      {
         fetchData();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pageParam, rowsPerPageParam, searchByParam, sortParam, groupByParam, customQueryParam, path, reload, globalReload, autoload, trigger]);

   const forceReload = useCallback(() =>
   {
      setReload({})
   }, []);

   const exportData = useCallback(async (fileType, hiddenColumns = []) =>
   {
      let params = {
         page: pageParam,
         page_size: rowsPerPageParam
      };

      if (searchByParam.length > 0)
      {
         params.global = searchByParam;
      }

      if (groupByParam.length > 0)
      {
         params.group_by = groupByParam;
      }

      if (sortParam)
      {
         if (sortParam.field)
         {
            params.order_field = sortParam.field;
         }
         params.order_asc = sortParam.order;
      }

      if (Object.keys(customQueryParam).length > 0)
      {
         Object.keys(customQueryParam).forEach(key =>
         {
            params[key] = customQueryParam[key];
         });
      }

      if (hiddenColumns.length > 0)
      {
         params['hidden_fields'] = hiddenColumns;
      }

      params['file_type'] = fileType;

      const queryString = new URLSearchParams(params).toString();

      const res = await request.get(`${path}?${queryString}`);

      if (error && res?.data?.result === 'error')
      {
         dispatcher(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: `Si è verificato un errore (${res?.data?.message})`,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      if (response.ok)
      {
         const currentTimestamp = Math.floor(Date.now() / 1000);
         let filename = `export-${currentTimestamp}.${fileType}`;

         const contentDisposition = response.headers.get('content-disposition');
         if (contentDisposition)
         {
            const parts = contentDisposition.split(';');
            filename = parts[1].split('=')[1].replaceAll("\"", "");
         }

         const blob = await response.blob();
         const a = document.createElement("a");
         a.href = window.URL.createObjectURL(blob);
         a.download = filename;
         a.click();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pageParam, rowsPerPageParam, searchByParam, sortParam, groupByParam, customQueryParam, path]);

   const onPageChange = (event, page) =>
   {
      if (!loading)
      {
         const nextPage = page + 1;
         dispatch({ type: PAGE_CHANGED, payload: nextPage });
         //setPage(nextPage);
      }

   }

   const onRowsPerPageChange = (event) =>
   {
      dispatch({ type: PAGE_CHANGED, payload: 1 });
      dispatch({ type: ROWS_PER_PAGE_CHANGED, payload: event.target.value });
      setRowsPerPage(event.target.value);
      setPage(0);
   }

   const onSortChange = (field, order, related = null) =>
   {
      field = related ? `${related}__${field.replace('.', '__')}` : field.replace('.', '__');

      dispatch({ type: SORT_CHANGED, payload: { field: field, order: !order } });
      setSortField(field);
      setSortOrder(!order);
   }

   const onGroupByChange = (field) =>
   {
      console.log('field', field);
      dispatch({ type: GROUPBY_CHANGED, payload: field });
      dispatch({ type: SORT_CHANGED, payload: { field: field, order: sortOrder } });
      setGroupBy(field);
      setSortField(field);
   }

   const onSearchSubmit = (string) =>
   {
      dispatch({ type: PAGE_CHANGED, payload: 1 });
      dispatch({ type: SEARCHBY_CHANGED, payload: string });
      setPage(1);
   }

   const onCustomQueryChange = (queryObject) =>
   {
      queryObject = { ...queryObject, timestamp: Date.now() };
      console.log('queryObject', queryObject);
      dispatch({ type: PAGE_CHANGED, payload: 1 });
      dispatch({ type: CUSTOM_QUERY_CHANGED, payload: queryObject });
      //dispatch({ type: TRIGGER_CHANGED, payload: {} });
      setPage(0);
      setCustomQuery(queryObject);
   }

   return <tableContext.Provider value={
      {
         page,
         setPage,
         rowsPerPage,
         setRowsPerPage,
         sortField,
         setSortField,
         sortOrder,
         setSortOrder,
         groupBy,
         setGroupBy,
         totalCount,
         onPageChange,
         onRowsPerPageChange,
         onSortChange,
         onGroupByChange,
         onCustomQueryChange,
         customQuery,
         setCustomQuery,
         isSearch,
         setIsSearch,
         onSearchSubmit,
         exportData,
         request,
         response,
         loading,
         error,
         autoload,
         setAutoload,
         isFirstRender,
         reload,
         forceReload,
         data,
         ...otherProps
      }
   }>{children}</tableContext.Provider>
}

TableProvider.propTypes = {
   children: PropTypes.node,
   defaultRowsPerPage: PropTypes.number,
   defaultSortField: PropTypes.string,
   defaultSortOrder: PropTypes.bool,
   defaultCustomQuery: PropTypes.object,
   defaultGroupBy: PropTypes.string,
   path: PropTypes.string,
   baseUrl: PropTypes.string,
   value: PropTypes.string,
   defaultAutoload: PropTypes.bool,
   otherProps: PropTypes.object
};