/* eslint-disable */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useTableProvider } from 'contexts/TableProvider';

//MUI
import
{
   Badge,
   Button,
   Grid,
   IconButton,
   CircularProgress,
   Tooltip,
} from '@mui/material';

//components
import GlobalSearch from './GlobalSearch';
import TextFilter from './TextFilter';
import DateFilter from './DateFilter';
import SelectFilter from './SelectFilter';
import ProvinceFilter from './ProvinceFilter';
import QualificationFilter from './QualificationFilter';
import ValutazioniFilter from './ValutazioniFilter';
import SwitchFilter from './SwitchFilter';
import AutocompleteFilter from './AutocompleteFilter';
import ButtonFilter from './ButtonFilter';
import IconButtonFilter from './IconButtonFilter';
import DateRangeFilter from './DateRangeFilter';

//icons
import
{
   faFilter
} from '@fortawesome/pro-solid-svg-icons';

const TableFilters = (props) =>
{

   const {
      filters,
      disable,
      visible = false,
      onGlobalSearchSubmit,
      submitButton = true,
      filtersToggler = true,
      ...otherProps
   } = props

   const {
      onCustomQueryChange,
      onSearchSubmit,
      customQuery,
      setCustomQuery,
      autoload,
      setAutoload,
   } = useTableProvider();

   const [showAll, setShowAll] = useState(false);
   const [appliedFilters, setAppliedFilters] = useState({});

   const handleFilterChange = (key, value) =>
   {
      setCustomQuery({ ...customQuery, [key]: value });
   }

   const handleFiltersFormSubmit = (event) =>
   {
      event.preventDefault();
      // const formData = new FormData(event.target);
      // const output = {};
      // formData.forEach((value, key) => output[key] = value);
      // console.log('output', output);
      // onCustomQueryChange(output);
      if (!autoload)
      {
         setAutoload(true);
      }

      Object.keys(customQuery).forEach(key =>
      {
         if (!customQuery[key] && typeof customQuery[key] !== 'boolean')
         {
            delete customQuery[key];
         }
      });

      onCustomQueryChange(customQuery);
   }

   const resetProvince = (name, multiple) =>
   {
      const newValue = multiple ? [] : '';
      setCustomQuery({ ...customQuery, [name]: newValue });
   }

   return (
      <form onSubmit={handleFiltersFormSubmit}>
         <Grid
            container
            rowSpacing={3}
            columnSpacing={2}
            {...otherProps}
         >
            {
               filters?.fields.map((field, index) =>
               {

                  let output;

                  switch (field?.type)
                  {
                     case 'globalSearch':
                        output = <GlobalSearch
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                           onGlobalSearchSubmit={onSearchSubmit}
                        />
                        break;
                     case 'text':
                     case 'tel':
                     case 'email':
                     case 'number':
                     case 'address':
                        output = <TextFilter
                           // onChange={(e) => setAppliedFilters({ ...appliedFilters, [field?.name]: e.target.value })}
                           // value={appliedFilters[field?.name]}
                           //onChange={(e) => setCustomQuery({ ...customQuery, [field?.name]: e.target.value })}
                           onChange={(e) => handleFilterChange(field?.name, e.target.value)}
                           value={customQuery[field?.name]}
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                        />
                        break;
                     case 'date':
                        output = <DateFilter
                           //onChange={(e) => setCustomQuery({ ...customQuery, [field?.name]: e.target.value })}
                           onChange={(e) => handleFilterChange(field?.name, e.target.value)}
                           value={customQuery[field?.name]}
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                        />
                        break;
                     case 'select':
                        output = <SelectFilter
                           // onChange={(e) => setAppliedFilters({ ...appliedFilters, [field?.name]: e.target.value })}
                           // value={appliedFilters[field?.name]}
                           //onChange={(e) => setCustomQuery({ ...customQuery, [field?.name]: e.target.value })}
                           value={customQuery[field?.name] || ''}
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                           onChange={(e) =>
                           {
                              handleFilterChange(field?.name, e.target.value);
                              if (field.onChange)
                              {
                                 field.onChange(e);
                              }
                           }}
                        />
                        break;
                     case 'autocomplete':

                        output = <AutocompleteFilter
                           //onChange={(e, value) => setCustomQuery({ ...customQuery, [field?.name]: value })}
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                           onChange={(e, value) =>
                           {
                              const valueParam = field?.valueparam;
                              let handledValue;
                              if (valueParam)
                              {
                                 handledValue = value?.[valueParam] || '';
                              } else
                              {
                                 handledValue = value;
                              }
                              handleFilterChange(field?.name, handledValue);
                              if (field.onChange)
                              {
                                 field.onChange(e, value);
                              }
                           }}
                        />
                        break;
                     case 'switch':
                        output = <SwitchFilter
                           //onChange={(e) => setCustomQuery({ ...customQuery, [field?.name]: e.target.value })}
                           onChange={(e) => handleFilterChange(field?.name, e.target.checked)}
                           defaultChecked={customQuery[field?.name] ? true : false}
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                        />
                        break;
                     case 'dateRange':
                        output = <DateRangeFilter
                           value={customQuery[field?.name] || undefined}
                           onChange={(e) => handleFilterChange(field?.name, e.target.value)}
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                        />
                        break;
                     case 'provinceSelector':
                        let value = customQuery[field?.name];
                        if (field?.multiple)
                        {
                           if (!Array.isArray(customQuery[field?.name]))
                           {
                              value = customQuery[field?.name]?.split(',') || [];
                           }
                        }
                        output = <ProvinceFilter
                           // onChange={(e) => setAppliedFilters({ ...appliedFilters, [field?.name]: e.target.value })}
                           // value={appliedFilters[field?.name]}
                           //onChange={(e) => setCustomQuery({ ...customQuery, [field?.name]: e.target.value })}
                           onChange={(e) => handleFilterChange(field?.name, e.target.value)}
                           resetValues={() => resetProvince(field?.name, field?.multiple)}
                           multiple={field?.multiple}
                           value={value || []}
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                        />
                        break;
                     case 'qualifications':
                        output = <QualificationFilter
                           // onChange={(e) => setAppliedFilters({ ...appliedFilters, [field?.name]: e.target.value })}
                           // value={appliedFilters[field?.name]}
                           //onChange={(e) => setCustomQuery({ ...customQuery, [field?.name]: e.target.value })}
                           onChange={(e) => handleFilterChange(field?.name, e.target.value)}
                           value={customQuery[field?.name]}
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                        />
                        break;
                     case 'valutations':
                        output = <ValutazioniFilter
                           // onChange={(e) => setAppliedFilters({ ...appliedFilters, [field?.name]: e.target.value })}
                           // value={appliedFilters[field?.name]}
                           //onChange={(e) => setCustomQuery({ ...customQuery, [field?.name]: e.target.value })}
                           onChange={(e) => handleFilterChange(field?.name, e.target.value)}
                           value={customQuery[field?.name]}
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                        />
                        break;
                     case 'button':
                        output = <ButtonFilter
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                        />
                        break;
                     case 'icon_button':
                        output = <IconButtonFilter
                           {...field}
                           {...(showAll && { visible: true })}
                           {...(disable && { disabled: true })}
                        />
                        break;
                     default:
                        break;
                  }

                  return (
                     <Grid
                        key={`filter-item-${index}`}
                        item
                        hidden={!field.visible}
                        {...(showAll && { hidden: false })}
                        {...field?.sizes || { lg: 2 }}
                     >
                        {output}
                     </Grid>
                  );

               })
            }
            {
               filtersToggler && (
                  <Grid item lg='auto' sx={{
                     ml: 'auto'
                  }}>
                     <Tooltip title="Mostra/Nascondi filtri">
                        <Badge color="error" variant={showAll ? 'dot' : ''}>
                           <IconButton
                              size="small"
                              disabled={disable}
                              onClick={() => setShowAll(showAll ? null : true)}
                              sx={{
                                 border: 'solid 1px #cccccc'
                              }}
                           >
                              <FontAwesomeIcon icon={faFilter} />
                           </IconButton>
                        </Badge>
                     </Tooltip>
                  </Grid>
               )
            }

            {
               submitButton && (
                  <Grid
                     item
                     lg='auto'
                     className='submitGridItem'
                  >
                     <Button
                     type="submit"
                     size="small"
                     variant="contained"
                     disabled={disable}
                     endIcon={(otherProps.loading) && <CircularProgress color="inherit" size={20} />}
                     >
                        <FormattedMessage id="common.filters.apply.label" defaultMessage="Applica" />
                     </Button>
                  </Grid>
               )
            }
         </Grid>
      </form>
   )
}

TableFilters.propTypes = {
   filters: PropTypes.object,
   disable: PropTypes.bool
};

export default TableFilters;