import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTableProvider } from 'contexts/TableProvider';

//MUI
import
{
   Box,
   Select,
   TextField,
   FormControl,
   MenuItem,
   InputLabel,
} from "@mui/material";

const DateRangeFilter = (props) =>
{
   const {
      label,
      name,
      visible,
      shortcuts = [],
      ...others
   } = props;

   const {
      customQuery,
      setCustomQuery
   } = useTableProvider();

   const [shortcutValue, setShortcutValue] = useState('');

   const handleShortCutChange = (event) =>
   {
      const index = event.target.value;
      console.log('shortcuts', shortcuts);
      setShortcutValue(index);
      const selection = shortcuts[index];
      setCustomQuery({ ...customQuery, [`${name}_dal`]: selection?.value?.start, [`${name}_al`]: selection?.value?.end });
   }

   const handleDateRangeChange = (key, e) =>
   {
      setCustomQuery({ ...customQuery, [key]: e.target.value });
      setShortcutValue('');
   }

   return (
      <FormControl
         fullWidth
         variant="outlined"
         size="small"
      >
         {
            label && <InputLabel shrink>{label}</InputLabel>
         }
         <Box
            component="div"
            sx={{
               display: 'flex',
            }}
         >
            {
               shortcuts?.length > 0 && (
                  <Select
                     {...others}
                     fullWidth
                     displayEmpty
                     value={shortcutValue}
                     onChange={handleShortCutChange}
                     sx={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        marginRight: '-1px',
                     }}
                     
                  >
                     <MenuItem value="">
                        <em>Seleziona</em>
                     </MenuItem>
                     {
                        shortcuts.map((shortcut, index) =>
                        {
                           return (
                              <MenuItem
                                 key={shortcut?.label}
                                 value={index.toString()}
                              >
                                 {shortcut?.label}
                              </MenuItem>
                           )
                        })
                     }
                  </Select>
               )
            }
            <TextField
               fullWidth
               type="date"
               disabled={!visible}
               variant="outlined"
               size="small"
               value={customQuery[`${name}_dal`] || ''}
               onChange={(e) => handleDateRangeChange(`${name}_dal`, e)}
               id={`${name}_dal`}
               name={`${name}_dal`}
               sx={{
                  marginRight: '-1px',
                  '& .MuiInputBase-root': {
                     borderTopRightRadius: 0,
                     borderBottomRightRadius: 0,
                     borderTopLeftRadius: shortcuts?.length && 0,
                     borderBottomLeftRadius: shortcuts?.length && 0
                  }
               }}
               InputLabelProps={{
                  shrink: true,
               }}
            />
            <TextField
               fullWidth
               type="date"
               disabled={!visible}
               variant="outlined"
               size="small"
               value={customQuery[`${name}_al`] || ''}
               onChange={(e) => handleDateRangeChange(`${name}_al`, e)}
               id={`${name}_al`}
               name={`${name}_al`}
               sx={{
                  '& .MuiInputBase-root': {
                     borderTopLeftRadius: 0,
                     borderBottomLeftRadius: 0
                  }
               }}
               InputLabelProps={{
                  shrink: true,
               }}
            />
         </Box>
      </FormControl>
   )
}

DateRangeFilter.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   shortcuts: PropTypes.array,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   visible: PropTypes.bool,
   value: PropTypes.string,
   onDateChange: PropTypes.func,
   useStepper: PropTypes.bool
};

export default DateRangeFilter;