import PropTypes from 'prop-types';
import { Controller } from "react-hook-form"

//MUI
import
{
   Switch,
   FormControlLabel,
} from "@mui/material"

const FormSwitch = (props) =>
{
   const {
      control,
      name,
      label,
      rules = {},
      parse,
      ...others
   } = props

   return <Controller
      control={control}
      name={name}
      rules={rules}
      parse={parse}
      render={({ field }) =>
      {
         return (
            <FormControlLabel
               {...others?.formcontrollabelprops}
               control={
                  <Switch
                     color={'success'}
                     {...field}
                     {...others}
                     size="small"
                     onChange={(event, data) =>
                     {
                        others.onChange ? others.onChange(event, data, field) : field.onChange(event, data);
                     }}
                  />}
               label={label}
            />
         )
      }}
   />
}

FormSwitch.propTypes = {
   control: PropTypes.object,
   name: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
   rules: PropTypes.object,
   defaultValue: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object, PropTypes.bool]),
   onChange: PropTypes.func,
   parse: PropTypes.any
};

export default FormSwitch;