/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import {
   FormControlLabel,
   Switch
} from "@mui/material";

const SwitchFilter = (props) =>
{
   const {
      label,
      name,
      id,
      visible,
      checked = false,
      value = true,
      onChange,
      ...otherProps
   } = props;

   return (
      <FormControlLabel
         labelPlacement="start"
         componentsProps={{
            typography: {
               sx: {
                  fontSize: '.75rem',
                  '&.MuiFormControlLabel-label': {
                     marginLeft: 0
                  }
               }
            }
         }}
         
         control={
            <Switch
               name={name}
               id={id}
               disabled={!visible}
               defaultChecked={checked}
               checked={checked}
               size="small"
               color={'primary'}
               value={value}
               onChange={onChange}
            />}
         label={label}
      />
   )
}

SwitchFilter.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   visible: PropTypes.bool,
   checked: PropTypes.bool,
   value: PropTypes.bool,
   onChange: PropTypes.func
};

export default SwitchFilter;