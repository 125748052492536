/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableProvider, useTableProvider } from 'contexts/TableProvider';
import { useForm } from 'react-hook-form';
import { useFetch } from "use-http";
import { useBee } from 'contexts/Bee';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';

// material-ui
import
{
   Alert,
   AlertTitle,
   Box,
   Button,
   CardContent,
   Chip,
   CircularProgress,
   Divider,
   Drawer,
   Grid,
   IconButton,
   Link,
   MenuItem,
   OutlinedInput,
   Stack,
   TablePagination,
   TextField,
   Typography,
   TableFooter,
   TableRow,
   TableCell
} from '@mui/material';

//project import
import { openSnackbar } from 'store/reducers/snackbar';

//Components
import MainCard from 'components/MainCard';
import TableFilters from 'components/TableFilters';
import SkeletonTable from 'components/SkeletonTable';
import ResourceTable from 'components/ResourceTable';
import MiniIconButton from 'components/MiniIconButton';
import FormSelect from 'components/FormSelect';
import FormTextField from 'components/FormTextField';
import FormAutocomplete from 'components/FormAutocomplete';
import FormSwitch from 'components/FormSwitch';
import TablePaginationActions from 'components/TablePaginationActions';

//icons
import
{
   faXmark,
   faPenToSquare,
   faTrashCan
} from '@fortawesome/pro-regular-svg-icons';

const TableFooterMarkup = (props) =>
{
   return (
      <Stack
         direction="row"
      >
         <TablePagination
            {...props}
         />
      </Stack>
   )
}

const UtentiMarkup = ({ addNewDrawer = false }) =>
{
   const dispatch = useDispatch();

   const {
      page,
      rowsPerPage,
      sortOrder,
      totalCount,
      onPageChange,
      onRowsPerPageChange,
      onSortChange,
      groupBy,
      onGroupByChange,
      loading,
      error,
      autoload,
      isFirstRender,
      forceReload,
      data
   } = useTableProvider();

   const {
      user: {
         settings: {
            tipi_utente_cms
         }
      }
   } = useAuth();

   const {
      stringifyData,
      prepareData
   } = useBee();

   const fetcher = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   const [utenteToEdit, setUtenteToEdit] = useState(null);
   const [deleting, setDeleting] = useState(null);
   const [updating, setUpdating] = useState(null);
   const [success, setSuccess] = useState(null);
   const [operatori, setOperatori] = useState([]);
   const [loadingDependencies, setLoadingDependencies] = useState(false);
   const [drawers, setDrawers] = useState({
      utente: false
   });

   const handleOperatoriAutocompleteOpen = useCallback(async (event, value) =>
   {

      setLoadingDependencies(true);

      const operatoriData = await fetcher.request.get(`/api/cms/utenti/erp/?global=${value}&page_size=50`);

      if (fetcher.error)
      {
         setLoadingDependencies(false);
         return [];
      }

      if (fetcher.response.ok)
      {
         setLoadingDependencies(false);
         setOperatori(operatoriData?.results);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [operatori.length]);

   const debouncedHandleOperatoriAutocompleteOpen = debounce(handleOperatoriAutocompleteOpen, 500);

   const defaults = useMemo(() =>
   {
      return {
         id: null,
         utente_erp: '',
         first_name: '',
         last_name: '',
         tipologia: '',
         email: '',
         cellulare: '',
         password: '',
         attivo: false
      }
   }, []);

   const formUtente = useForm({
      defaultValues: defaults
   });

   const watchUtenteErp = formUtente.watch("utente_erp", false)

   useEffect(() =>
   {
      if (addNewDrawer)
      {
         setDrawers({
            ...drawers, utente: true
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [addNewDrawer]);

   useEffect(() =>
   {

      if (!loading && updating)
      {
         setUpdating(null);
      } else if (!loading && deleting)
      {
         setDeleting(null);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [loading]);

   useEffect(() => 
   {
      if (success !== null)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: success?.message,
               variant: 'alert',
               alert: {
                  color: 'success'
               },
               close: false
            })
         );
      }

      return () =>
      {
         setSuccess(null);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [success]);

   useEffect(() => 
   {
      if (fetcher.error)
      {

         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: fetcher.response.data?.message || fetcher.error?.message,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fetcher.error]);

   useEffect(() =>
   {

      if (utenteToEdit !== null)
      {
         console.log('useEffect', utenteToEdit);
         const clone = { ...utenteToEdit };
         formUtente.reset(stringifyData(clone, 'tipologia'));
         setDrawers({ ...drawers, utente: true });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [utenteToEdit]);

   const filters = useMemo(() =>
   {
      return {
         fields: [
            {
               type: 'globalSearch',
               label: 'Ricerca globale',
               visible: true,
               id: 'globalsearch'
            },
            {
               type: 'email',
               label: 'E-mail',
               visible: true,
               name: 'email',
               id: 'email',
            },
            {
               type: 'text',
               label: 'Telefono',
               visible: true,
               name: 'cellulare',
               id: 'cellulare',
            },
            {
               type: 'select',
               label: 'Stato',
               visible: true,
               name: 'stato',
               id: 'stato',
               options: [{
                  label: 'Tutti',
                  value: 'T'
               }, {
                  label: 'Attivo',
                  value: 'A'
               },
               {
                  label: 'Non attivo',
                  value: 'N'
               }]
            },
            {
               type: 'select',
               label: 'Tipologia',
               visible: true,
               name: 'tipologia_id',
               id: 'tipologia_id',
               options: tipi_utente_cms !== undefined && tipi_utente_cms?.length > 0 && tipi_utente_cms.map((item, index) =>
               {
                  return {
                     label: item.titolo,
                     value: item.id
                  }
               })
            }
         ]
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


   const columns = useMemo(
      () => [
         {
            Header: 'Nome',
            accessor: 'first_name',
            sortable: true,
         },
         {
            Header: 'Cognome',
            accessor: 'last_name',
            sortable: true
         },
         {
            Header: 'Tipologia',
            accessor: 'tipologia.titolo',
            sortable: true,
            Cell: ({ value }) =>
            {
               return (
                  <Chip
                     label={value}
                  />
               )
            }
         },
         {
            Header: 'E-mail',
            accessor: 'email',
            sortable: true,
            Cell: ({ value }) =>
            {
               return (
                  <Link
                     href={`mailto:${value}`}
                     target="_blank"
                  >
                     {value}
                  </Link>
               )
            }
         },
         {
            Header: 'Telefono',
            accessor: 'cellulare'
         },
         {
            Header: 'Attivo',
            accessor: 'attivo',
            Cell: ({ value }) =>
            {
               return (
                  <Chip
                     label={value ? 'Si' : 'No'}
                     color={value ? 'success' : 'error'}
                  />
               )
            }
         },
         {
            Header: 'Creato il',
            accessor: 'creato_il',
            sortable: true,
            Cell: ({ value }) =>
            {
               return moment(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm')
            }
         },
         {
            accessor: 'id',
            Cell: ({ row, value }) =>
            {
               return (
                  <Stack
                     direction={'row'}
                     justifyContent={'flex-end'}
                     alignItems={'center'}
                  >
                     <MiniIconButton
                        size='small'
                        onClick={() => setUtenteToEdit(row?.original)}
                     >
                        <FontAwesomeIcon
                           icon={faPenToSquare}
                        />
                     </MiniIconButton>
                     {
                        deleting === value ? (
                           <MiniIconButton
                              color="error"
                              size="small"
                           >
                              <CircularProgress color="error" size={14} />
                           </MiniIconButton>
                        ) : (
                           <MiniIconButton
                              color="error"
                              onClick={(event) => onDeleteUtente(event, value)}
                              size="small"
                              disabled={deleting}
                           >
                              <FontAwesomeIcon icon={faTrashCan} />
                           </MiniIconButton>
                        )
                     }
                  </Stack>
               )
            }
         }
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [deleting]
   );

   const initialState = useMemo(
      () => ({
         columnOrder: [
            'first_name',
            'last_name',
            'tipologia.titolo',
            'email',
            'cellulare',
            'attivo',
            'creato_il'
         ]
      }),
      []
   );

   const renderContent = () =>
   {
      if (error)
      {
         return (
            <Alert
               severity="error"
            >
               <>
                  <AlertTitle
                     sx={{
                        fontWeight: 700
                     }}
                  >
                     Errore server
                  </AlertTitle>
                  {`Si è verificato un problema durante il recupero dei dati (Error: ${fetcher.response?.data?.message || error})`}
               </>
            </Alert>
         )
      } else if (!autoload)
      {
         return (
            <Alert
               severity="info"
            >
               <>
                  <AlertTitle
                     sx={{
                        fontWeight: 700
                     }}
                  >
                     Attenzione
                  </AlertTitle>
                  Inizia impostando alcuni filtri per poter visualizzare risultati
               </>
            </Alert>
         )
      } else if (loading && isFirstRender)
      {
         return (
            <SkeletonTable rows={5} columns={6} />
         )
      } else if (data.results)
      {
         return (
            <ResourceTable
               columns={columns}
               data={data}
               initialState={initialState}
               orderAsc={sortOrder}
               onSortChange={onSortChange}
               groupByField={groupBy}
               onGroupByChange={onGroupByChange}
               footer={
                  <TableFooter>
                     <TableRow>
                        <TableCell colSpan={columns?.length}>
                           <Stack
                              direction={'row'}
                              justifyContent={'flex-start'}
                              alignItems={'center'}
                           >
                              <TableFooterMarkup
                                 page={!totalCount || totalCount <= 0 ? 0 : page}
                                 count={totalCount}
                                 onPageChange={onPageChange}
                                 rowsPerPageOptions={[10, 25, 50, 100]}
                                 component='div'
                                 rowsPerPage={rowsPerPage}
                                 onRowsPerPageChange={onRowsPerPageChange}
                                 labelRowsPerPage='Risultati per pagina'
                                 ActionsComponent={TablePaginationActions}
                                 backIconButtonProps={{
                                    disabled: loading || page === 0
                                 }}
                                 nextIconButtonProps={{
                                    disabled: loading || page >= Math.ceil(totalCount / rowsPerPage) - 1
                                 }}
                                 labelDisplayedRows={({ from, to, count }) =>
                                 {
                                    return `${from}–${to} di ${count !== -1 ? count : `altre ${to}`}`;
                                 }}
                                 SelectProps={{
                                    name: "page_size"
                                 }}
                                 sx={{
                                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                                       marginTop: 0,
                                       marginBottom: 0
                                    },
                                    '& .MuiToolbar-root': {
                                       paddingLeft: 0
                                    }
                                 }}
                              />
                              {
                                 loading && !isFirstRender && <CircularProgress color='inherit' size={16} sx={{ ml: 2 }} />
                              }
                           </Stack>
                        </TableCell>
                     </TableRow>
                  </TableFooter>

               }
            />
         )
      }
   }

   const onEditUtente = async (formData) =>
   {
      if (utenteToEdit !== null)
      {
         setSuccess(null);

         setUpdating(true);

         const id = utenteToEdit?.id;

         formData = prepareData(formData, ['tipologia']);

         const container = {
            data: formData
         }

         const updated = await fetcher.request.put(`/api/cms/utenti/${id}/`, container);
         console.table(updated);

         if (fetcher.error)
         {
            // setUtenteToEdit(null);
            // formUtente.reset(defaults);
            return;
         }

         if (fetcher.response.ok)
         {
            setSuccess({
               message: 'Utente modificato con successo'
            });
            setDrawers({ ...drawers, utente: false });
            forceReload();
         }
      }

   }

   const onAddUtente = async (formData) =>
   {

      setSuccess(null);

      if (formData?.utente_erp === '')
      {
         delete formData.utente_erp;
      }

      formData = prepareData(formData, ['tipologia']);

      const container = {
         data: formData
      }

      const added = await fetcher.request.post(`/api/cms/utenti/`, container);
      console.table(added);

      if (fetcher.error)
      {
         return;
      }

      if (fetcher.response.ok)
      {
         setSuccess({
            message: 'Utente aggiunto con successo'
         });
         setDrawers({ ...drawers, utente: false });
         forceReload();
      }

   }

   const onDeleteUtente = async (event, id) =>
   {
      setSuccess(null);

      if (!window.confirm('Sei sicuro di voler eliminare questo utente?\x0Dl\x27operazione non potrà essere annullata'))
      {
         return;
      }

      setDeleting(id);

      const deleted = await fetcher.request.delete(`/api/cms/utenti/${id}`);
      console.table(deleted);

      if (fetcher.error)
      {
         setDeleting(null);
         return;
      }

      if (fetcher.response.ok)
      {
         setDeleting(null);
         setSuccess({ message: 'Utente eliminato con successo' });
         forceReload();
      }
   }

   const HelperTextErrorStyle = useMemo(() =>
   {
      return {
         mx: 0
      }
   }, []);

   return (
      <>
         <MainCard
            content={false}
            title='Elenco Utenti'
            secondary={loading && !isFirstRender && <CircularProgress color='inherit' size={16} sx={{ ml: 2 }} />}
         >
            <Stack
               sx={{
                  px: 2.75,
                  pt: 2.75
               }}
            >
               <TableFilters
                  filters={filters}
                  disable={loading}
                  filtersToggler={false}
               />
            </Stack>
            <CardContent
               sx={{
                  px: 0,
                  '&:last-child': {
                     pb: 0
                  }
               }}
            >
               {renderContent()}
            </CardContent>
         </MainCard>
         <Drawer
            anchor='right'
            open={drawers.utente}
            onClose={() => setDrawers({ ...drawers, utente: false })}
            sx={{ zIndex: 1300 }}
            SlideProps={{
               onExited: () =>
               {
                  setUtenteToEdit(null);
                  formUtente.reset(defaults);
               }
            }}
         >
            <Box sx={{
               width: '350px',
               p: '2rem'
            }}>

               <Stack justifyContent="space-between" direction="row">
                  <Typography variant="h4">
                     {utenteToEdit !== null ? 'Modifica utente' : 'Aggiungi utente'}
                  </Typography>
                  <IconButton
                     onClick={() => setDrawers({ ...drawers, utente: false })}
                  >
                     <FontAwesomeIcon icon={faXmark} />
                  </IconButton>
               </Stack>
               <Grid container rowSpacing={3} columnSpacing={2.75} sx={{ mt: 1 }}>
                  {
                     utenteToEdit === null && (
                        <>
                           <Grid item lg={12}>
                              <Divider>
                                 <Chip label="Seleziona un operatore esistente" size="small" />
                              </Divider>
                           </Grid>
                           <Grid item lg={12}>
                              <FormAutocomplete
                                 control={formUtente.control}
                                 name="utente_erp"
                                 size="small"
                                 label="Operatore ERP"
                                 autoComplete
                                 loading={loadingDependencies}
                                 loadingText={'Loading...'}
                                 onInputChange={debouncedHandleOperatoriAutocompleteOpen}
                                 isOptionEqualToValue={(option, value) =>
                                 {
                                    if (!value || !option)
                                    {
                                       return false;
                                    }
                                    return option.id === value.id;
                                 }}
                                 noOptionsText={'Nessun operatore trovato'}
                                 options={operatori}
                                 ListboxProps={{
                                    sx: {
                                       p: 0
                                    }
                                 }}
                                 InputLabelProps={{
                                    shrink: true,
                                 }}
                                 getOptionLabel={(option) =>
                                 {
                                    return option ? `${option?.first_name} ${option?.last_name}` : ''
                                 }}
                                 renderOption={(props, option) => (
                                    <Stack
                                       component="li"
                                       direction={"row"}
                                       {...props}
                                       sx={{
                                          '&.MuiStack-root': {
                                             justifyContent: 'space-between'
                                          }
                                       }}
                                    >
                                       <Typography
                                          component={'span'}
                                       >
                                          {option?.first_name} {option?.last_name}
                                       </Typography>
                                       <Typography
                                          component={'span'}
                                          color={(theme) => theme.palette.text.secondary}
                                          sx={{
                                             paddingLeft: 2
                                          }}
                                       >
                                          {option?.cellulare}
                                       </Typography>
                                    </Stack>
                                 )}
                                 disabled={formUtente.formState.isSubmitting}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       label="Operatore ERP"
                                       variant="outlined"
                                       InputLabelProps={{
                                          shrink: true
                                       }}
                                    />
                                 )}
                              />
                           </Grid>
                           <Grid item lg={12}>
                              <Divider>
                                 <Chip label="Oppure " size="small" />
                              </Divider>
                           </Grid>
                        </>
                     )
                  }
                  <Grid item lg={12}>
                     <FormTextField
                        name="first_name"
                        control={formUtente.control}
                        size="small"
                        fullWidth
                        label="Nome"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        InputProps={{
                           autoComplete: 'new-password'
                        }}
                        required
                        rules={
                           {
                              required: {
                                 value: Boolean(!watchUtenteErp),
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        disabled={formUtente.formState.isSubmitting || Boolean(watchUtenteErp)}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormTextField
                        name="last_name"
                        control={formUtente.control}
                        size="small"
                        fullWidth
                        label="Cognome"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        InputProps={{
                           autoComplete: 'new-password'
                        }}
                        required
                        rules={
                           {
                              required: {
                                 value: Boolean(!watchUtenteErp),
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        disabled={formUtente.formState.isSubmitting || Boolean(watchUtenteErp)}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormSelect
                        control={formUtente.control}
                        name="tipologia"
                        size="small"
                        displayEmpty
                        label='Tipologia'
                        input={<OutlinedInput notched label="Tipologia" />}
                        defaultValue=""
                        required
                        rules={
                           {
                              required: {
                                 value: true,
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        formControlProps={
                           {
                              fullWidth: true,
                              required: true
                           }
                        }
                        disabled={formUtente.formState.isSubmitting}
                     >
                        <MenuItem
                           value=""
                        >
                           Seleziona
                        </MenuItem>
                        {
                           tipi_utente_cms !== undefined && tipi_utente_cms?.length > 0 && tipi_utente_cms.map((item, index) =>
                           {
                              return (
                                 <MenuItem
                                    key={index}
                                    value={JSON.stringify(item)}
                                 >
                                    {item?.titolo}
                                 </MenuItem>
                              )
                           })
                        }
                     </FormSelect>
                  </Grid>
                  <Grid item lg={12}>
                     <FormTextField
                        name="cellulare"
                        type="tel"
                        control={formUtente.control}
                        size="small"
                        fullWidth
                        label="Telefono"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        InputProps={{
                           autoComplete: 'new-password'
                        }}
                        required
                        rules={
                           {
                              required: {
                                 value: Boolean(!watchUtenteErp),
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        disabled={formUtente.formState.isSubmitting || Boolean(watchUtenteErp)}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormSwitch
                        name="attivo"
                        control={formUtente.control}
                        size="small"
                        color="success"
                        disabled={formUtente.formState.isSubmitting}
                        sx={{
                           ml: '11px'
                        }}
                        label={
                           <Typography
                              noWrap
                              component="span"
                              variant="body2"
                           >Attivo</Typography>
                        }
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <Divider>
                        <Chip label="Informazioni account" size="small" />
                     </Divider>
                  </Grid>
                  <Grid item lg={12}>
                     <FormTextField
                        name="email"
                        type="email"
                        control={formUtente.control}
                        size="small"
                        fullWidth
                        label="E-mail"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        InputProps={{
                           autoComplete: 'new-password'
                        }}
                        disabled={formUtente.formState.isSubmitting || Boolean(watchUtenteErp)}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormTextField
                        name="password"
                        type="password"
                        control={formUtente.control}
                        size="small"
                        fullWidth
                        label="New Password"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        InputProps={{
                           autoComplete: 'new-password'
                        }}
                        disabled={formUtente.formState.isSubmitting || Boolean(watchUtenteErp)}
                     />
                  </Grid>
                  {
                     utenteToEdit !== null && (
                        <Grid item lg={12}>
                           <TextField
                              FormHelperTextProps={{
                                 sx: HelperTextErrorStyle
                              }}
                              InputLabelProps={{
                                 shrink: true
                              }}
                              label="Codice di accesso"
                              size="small"
                              fullWidth
                              value={formUtente.getValues('codice_accesso')}
                              readOnly
                              disabled
                              sx={{
                                 '& .Mui-disabled, & .MuiInputBase-root': {
                                    color: (theme) => theme.palette.text.primary,
                                    '-webkit-text-fill-color': (theme) => theme.palette.text.primary
                                 }
                              }}
                           />
                        </Grid>
                     )
                  }
                  <Grid item lg={12} align="right">
                     {
                        utenteToEdit !== null ? (
                           <Button sx={{
                              ml: 1
                           }}
                              size="small"
                              variant="contained"
                              disabled={!formUtente.formState.isDirty}
                              onClick={formUtente.handleSubmit(onEditUtente)}
                              endIcon={formUtente.formState.isSubmitting && <CircularProgress color="inherit" size={20} />}
                           >
                              Applica modifiche
                           </Button>
                        ) : (
                           <Button sx={{
                              ml: 1
                           }}
                              size="small"
                              variant="contained"
                              onClick={formUtente.handleSubmit(onAddUtente)}
                              endIcon={formUtente.formState.isSubmitting && <CircularProgress color="inherit" size={20} />}
                           >
                              Aggiungi
                           </Button>
                        )
                     }
                  </Grid>
               </Grid>
            </Box>
         </Drawer>
      </>
   )
}

UtentiMarkup.propTypes = {
   value: PropTypes.any,
   row: PropTypes.object,
   addNewDrawer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.func])
};

const Utenti = ({ addNewDrawer = false }) =>
{
   return (
      <TableProvider
         path="/api/cms/utenti/"
      >
         <UtentiMarkup addNewDrawer={addNewDrawer} />
      </TableProvider>
   )
}

Utenti.propTypes = {
   addNewDrawer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.func])
};

export default Utenti;