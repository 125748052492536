/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */

import React, { useContext, createContext, useState, useEffect } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { useFetch } from 'use-http';
import { useParams } from 'react-router';
import { useBee } from './Bee';

const servizioContext = createContext();

export function useServizio()
{
   return useContext(servizioContext);
}

export function ServizioCore({ children, ...otherProps })
{

   const {
      idContratto,
      idServizio,
      idGiorno,
      idTurno,
   } = useParams();

   const {
      forceReload,
      reload,
   } = useBee();

   //inizializzo l'oggetto per la chiamata fetch
   const fetcher = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   const [servizio, setServizio] = useState(null);
   const [giorno, setGiorno] = useState(null);
   const [turni, setTurni] = useState(null);
   const [turno, setTurno] = useState(null);

   const [unassigningIds, setUnassigningIds] = useState({});
   const [assigningIds, setAssigningIds] = useState({});
   const [confirmIds, setConfirmIds] = useState({});
   const [unconfirmIds, setUnconfirmIds] = useState({});
   const [requesting, setRequesting] = useState(false);

   useEffect(() =>
   {

      const fetchData = async () =>
      {

         setRequesting(true);

         const data = await fetcher.get(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/`);

         if (fetcher.error)
         {
            setRequesting(false);
            return;
         }

         if (fetcher.response.ok)
         {
            setServizio(data);
            const d = data?.giorni?.find((g) => parseInt(g.id) === parseInt(idGiorno));
            if (d)
            {
               setGiorno(d);
               setTurni(idTurno ? d.turni.filter((t) => parseInt(t.id) === parseInt(idTurno)) : d.turni);
               const t = d.turni.find((t) => parseInt(t.id) === parseInt(idTurno));
               if (t)
               {
                  setTurno(t);
               }
            }
            setRequesting(false);
         }

      }

      fetchData();

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [idContratto, idServizio, idTurno, idGiorno]);

   const unassignOperatore = async (id) =>
   {
      setRequesting(true);

      setUnassigningIds({ ...unassigningIds, [id]: true });

      const container = {
         data: {
            operatore: {
               id: id
            }
         }
      };

      const unassigned = await fetcher.request.post(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/operatori/`, container);

      if (fetcher.response.ok && unassigned)
      {
         setUnassigningIds({ ...assigningIds, [id]: false });
         forceReload();
         setRequesting(false);
      }

      if (fetcher.error)
      {
         setRequesting(false);
      }
   }

   const assignOperatore = async (id) =>
   {
      setRequesting(true);

      setAssigningIds({ ...assigningIds, [id]: true });

      const container = {
         data: {
            operatore: {
               id: id
            },
            add: true
         }
      };

      const assigned = await fetcher.request.post(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/operatori/`, container);

      if (fetcher.response.ok && assigned)
      {
         setAssigningIds({ ...assigningIds, [id]: false });
         forceReload();
         setRequesting(false);
      }

      if (fetcher.error)
      {
         setRequesting(false);
      }

   }

   const confirmOperatore = async (id) =>
   {

      setRequesting(true);

      setConfirmIds({ ...confirmIds, [id]: true });

      const container = {
         data: {
            operatore: {
               id: id
            },
            add: true
         }
      };

      const confirm = await fetcher.request.post(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/operatori-conf/`, container);

      if (fetcher.response.ok && confirm)
      {
         setConfirmIds({ ...confirmIds, [id]: false });
         forceReload();
         setRequesting(false);
      }

      if (fetcher.error)
      {
         setRequesting(false);
      }

   }

   const unconfirmOperatore = async (id) =>
   {

      setRequesting(true);

      setUnconfirmIds({ ...unconfirmIds, [id]: true });

      const container = {
         data: {
            operatore: {
               id: id
            }
         }
      };

      const unconfirm = await fetcher.request.post(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/operatori-conf/`, container);

      if (fetcher.response.ok && unconfirm)
      {
         setUnconfirmIds({ ...unconfirmIds, [id]: false });
         forceReload();
         setRequesting(false);
      }

      if (fetcher.error)
      {
         setRequesting(false);
      }

   }

   return (
      <servizioContext.Provider value={
         {
            //metodi
            unassignOperatore,
            assignOperatore,
            confirmOperatore,
            unconfirmOperatore,
            forceReload,
            assigningIds,
            setAssigningIds,
            unassigningIds,
            setUnassigningIds,
            confirmIds,
            setConfirmIds,
            unconfirmIds,
            setUnconfirmIds,
            requesting,
            servizio,
            giorno,
            turni,
            turno,
            ...otherProps
         }
      }>
         {children}
      </servizioContext.Provider>
   );
}

ServizioCore.propTypes = {
   idContratto: oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   idServizio: oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   idGiorno: oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   idTurno: oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   otherProps: PropTypes.object
};

ServizioCore.propTypes = {
   children: PropTypes.node,
};