/* eslint-disable no-useless-catch */
import React, { useContext, createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import province from 'data/province-italia';

const beeContext = createContext();

export function useBee()
{
   return useContext(beeContext);
}

export function BeeCore({ children })
{
   const [fetching, setFetching] = useState(false);
   const [reload, setReload] = useState();
   const [pageTitle, setPageTitle] = useState('');
   const [pageSubtitle, setPageSubtitle] = useState('');
   const [breadcrumbs, setBreadcrumbs] = useState([]);

   const formatSecondsToHours = (seconds) =>
   {
      let hours = Math.floor(seconds / 3600);
      if (hours < 10)
      {
         hours = `0${hours}`;
      }
      let minutes = Math.floor((seconds % 3600) / 60);
      if (minutes < 10)
      {
         minutes = `0${minutes}`;
      }
      return `${hours}:${minutes.toString()}`;
   }

   const convertHoursToSeconds = (hours) =>
   {
      const [hoursValue, minutesValue] = hours.split(':');
      const seconds = (parseInt(hoursValue, 10) * 3600) + (parseInt(minutesValue, 10) * 60);
      return seconds;
   }

   /* validateCf */

   const validateCf = (cf) =>
   {
      var validi, i, s, set1, set2, setpari, setdisp;
      if (cf == '') return '';
      cf = cf.toUpperCase();
      if (cf.length != 16)
         return false;
      validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      for (i = 0; i < 16; i++)
      {
         if (validi.indexOf(cf.charAt(i)) == -1)
            return false;
      }
      set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
      setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
      s = 0;
      for (i = 1; i <= 13; i += 2)
         s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
      for (i = 0; i <= 14; i += 2)
         s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
      if (s % 26 != cf.charCodeAt(15) - 'A'.charCodeAt(0))
         return false;
      return true;
   }

   const convertBase64 = (file) =>
   {
      if (file)
      {
         return new Promise((resolve, reject) =>
         {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () =>
            {
               resolve(fileReader.result);
            };

            fileReader.onerror = (error) =>
            {
               reject(error);
            };
         });
      }

      return ''
   };

   const getMediaSrc = (value) =>
   {

      return value?.data ? value?.data : process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_API_URL + value : value;

   }

   const forceReload = useCallback(() =>
   {
      setReload({})
   }, []);

   const formatMoney = (amount) =>
   {
      const formatter = new Intl.NumberFormat('it-IT', {
         style: 'currency',
         currency: 'EUR',
         minimumFractionDigits: 2,
         maximumFractionDigits: 2
      });

      return formatter.format(amount);
   }

   const generateCoords = async (indirizzo, num, cap, citta, prov) =>
   {
      let query = [
         indirizzo, num, cap, `${citta} (${prov})`
      ].filter(Boolean).join(',+');

      //const response = await fetch(`https://geocode.maps.co/search?q=${query.replace(/ /g, "+")}&api_key=661fbfc17a7bf807513654ktj9f5fdf`).then(res => res.json());

      try{
         const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${query.replace(/ /g, "+")}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`).then(res => res.json());
         console.log('response', response);
         if (response.status !== 'OK')
         {
            let message = '';
            switch(response.status)
            {
               case 'ZERO_RESULTS':
                  message = 'Non ho trovato risultati per questa ricerca';
                  break;
               default:
                  message = 'Si è verificato un problema durante la ricerca';
                  break;
            }
            throw new Error(message);
         }
   
         const first = response.results.shift();
   
         return {
            lat: first?.geometry.location?.lat,
            lng: first?.geometry?.location?.lng
         }
      }catch(error){
         throw error;
      }
   }

   const prepareData = (data, keysToParse = []) => {

      if( data )
      {
         for (let key in data)
         {
            if( data[key] === '' )
            {
               data[key] = null;
            }else if( keysToParse.includes(key) )
            {
               data[key] = data[key] ? JSON.parse(data[key]) : data[key];
            }
         }
      }

      return data;
   }

   const stringifyData = (data, keysToParse = []) => {

      if( data )
      {
         for (let key in data)
         {
            if( keysToParse.includes(key) )
            {
               data[key] = data[key] ? JSON.stringify(data[key]) : data[key];
            }
         }
      }

      return data;
   }

   return (
      <beeContext.Provider value={
         {
            //metodi
            fetching,
            setFetching,
            validateCf,
            convertBase64,
            getMediaSrc,
            reload,
            forceReload,
            formatMoney,
            generateCoords,
            formatSecondsToHours,
            convertHoursToSeconds,
            prepareData,
            stringifyData,
            pageTitle,
            setPageTitle,
            pageSubtitle,
            setPageSubtitle,
            breadcrumbs,
            setBreadcrumbs,
            //oggetti
            province,
         }
      }>
         {children}
      </beeContext.Provider>
   );
}

BeeCore.propTypes = {
   children: PropTypes.node,
};
