/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteProtection from './RouteProtection';

//REPORT
const ReportRisorseAssegnate = Loadable(lazy(() => import('pages/report/risorse-assegnate')));
const ReportCompensi = Loadable(lazy(() => import('pages/report/compensi')));
const ReportRicaviAttivita = Loadable(lazy(() => import('pages/report/ricavi-attivita')));
const ReportAnalisiRicavi = Loadable(lazy(() => import('pages/report/analisi-ricavi')));
const ReportScadenzeCorsi = Loadable(lazy(() => import('pages/report/scadenze-corsi')));
const ReportScadenzeDocumenti = Loadable(lazy(() => import('pages/report/scadenze-documenti')));
const ReportPratiche = Loadable(lazy(() => import('pages/report/pratiche')));

// ==============================|| MAIN ROUTING ||============================== //

const ReportRoutes = (user) =>
{
   return {
      path: '/',
      children: [
         {
            path: '/',
            element: (
               <AuthGuard>
                  <MainLayout />
               </AuthGuard>
            ),
            children: [
               {
                  path: 'report',
                  children: [
                     {
                        path: 'risorse-assegnate',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_risorse-assegnate']}
                           >
                              <ReportRisorseAssegnate />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'compensi',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_compensi','erp_compensi_compensi']}
                           >
                              <ReportCompensi />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'ricavi-attivita',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_ricavi-attivita', 'erp_ricavi-attivita_ricavi']}
                           >
                              <ReportRicaviAttivita />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'analisi-ricavi',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin']}
                           >
                              <ReportAnalisiRicavi />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'scadenze-corsi',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_scadenze-corsi']}
                           >
                              <ReportScadenzeCorsi />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'scadenze-documenti',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_scadenze-documenti']}
                           >
                              <ReportScadenzeDocumenti />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'pratiche',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin']}
                           >
                              <ReportPratiche/>
                           </RouteProtection>
                        )
                     }
                  ]
               }
            ]
         },
         {
            path: '*',
            element: (
               <p>Not found</p>
            )
         }
      ]
   }
}

export default ReportRoutes;
