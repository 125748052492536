/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useTableProvider } from 'contexts/TableProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//MUI
import
   {
      Box,
      Button,
      TextField
   } from "@mui/material";

import
{
   faPlus,
   faMinus
} from '@fortawesome/pro-regular-svg-icons';


const DateFilter = (props) =>
{
   const {
      label,
      name,
      id,
      visible,
      value = '',
      useStepper = false,
      ...otherprops
   } = props;

   const {
      customQuery,
      setCustomQuery
   } = useTableProvider();

   const handleChangeDay = (i) =>
   {
      const currentDate = new Date(value);
      if (i)
      {
         currentDate.setDate(currentDate.getDate() + 1);
      }
      else
      {
         currentDate.setDate(currentDate.getDate() - 1);
      }
      const newValue = currentDate.toISOString().slice(0, 10);
      setCustomQuery({ ...customQuery, [name]: newValue });
      console.log(newValue);
   };

   return (
      <Box
         component="div"
         sx={{
            display: 'flex',
         }}
      >
         {
            props?.useStepper && (
               <Button
               variant="outlined"
               size="small"
               onClick={() => handleChangeDay(0)}
               sx={{
                  minWidth: 'unset'
               }}
               >
                  <FontAwesomeIcon icon={faMinus} />
               </Button>
            )
         }
         <TextField
            fullWidth
            label={label}
            name={name}
            id={id}
            type="date"
            disabled={!visible}
            variant="outlined"
            size="small"
            value={value}
            {...otherprops}
            InputLabelProps={{
               shrink: true,
            }}
         />
         {
            props?.useStepper && (
               <Button
               variant="outlined"
               size="small"
               onClick={() => handleChangeDay(1)}
               sx={{
                  minWidth: 'unset'
               }}
               >
                  <FontAwesomeIcon icon={faPlus} />
               </Button>
            )
         }
      </Box>
   )
}

DateFilter.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   visible: PropTypes.bool,
   value: PropTypes.string,
   onDateChange: PropTypes.func,
   useStepper: PropTypes.bool
};

export default DateFilter;