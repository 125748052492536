/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteProtection from './RouteProtection';

//PIANIFICAZIONE
const Contratti = Loadable(lazy(() => import('pages/pianificazione/contratti')));
import Contratto from 'pages/pianificazione/contratto';
const NuovoContratto = Loadable(lazy(() => import('pages/pianificazione/contratto/nuovo')));
const TabDatiGenerali = Loadable(lazy(() => import('pages/pianificazione/contratto/dati-generali')));
const TabServizi = Loadable(lazy(() => import('pages/pianificazione/contratto/servizi')));

//import AssegnazioneRisorse from 'pages/pianificazione/assegnazione-risorse';
import MainServizio from 'pages/pianificazione/contratto/servizi/servizio'; //sostituisce AssegnazioneRisorse
//const TabServizio = Loadable(lazy(() => import('pages/pianificazione/assegnazione-risorse/servizio')));
const TabServizio = Loadable(lazy(() => import('pages/pianificazione/contratto/servizi/servizio/tab-servizio')));
//const TabNoteEdAllegati = Loadable(lazy(() => import('pages/pianificazione/assegnazione-risorse/note-ed-allegati')));
const TabNoteEdAllegati = Loadable(lazy(() => import('pages/pianificazione/contratto/servizi/servizio/tab-note-ed-allegati')));
//const TabCosti = Loadable(lazy(() => import('pages/pianificazione/assegnazione-risorse/costi')));
const TabCosti = Loadable(lazy(() => import('pages/pianificazione/contratto/servizi/servizio/tab-costi')));

import AssegnazioneAvanzata from 'pages/pianificazione/assegnazione-avanzata';
const TabPrezzi = Loadable(lazy(() => import('pages/pianificazione/contratto/prezzi')));
const TabCompensi = Loadable(lazy(() => import('pages/pianificazione/contratto/compensi')));

import ConsolidamentoAvanzato from 'pages/pianificazione/consolidamento-avanzato';
const PianificazioneIntermittenti = Loadable(lazy(() => import('pages/pianificazione/intermittenti')));

// ==============================|| MAIN ROUTING ||============================== //

const PianificazioneRoutes = (user) =>
{
   return {
      path: '/',
      children: [
         {
            path: '/',
            element: (
               <AuthGuard>
                  <MainLayout />
               </AuthGuard>
            ),
            children: [
               {
                  path: 'pianificazione',
                  children: [
                     {
                        path: 'contratti',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                           >
                              <Contratti />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'contratti/aggiungi-nuovo',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                           >
                              <NuovoContratto />
                           </RouteProtection>
                        ),
                     },
                     {
                        path: 'contratti/:idContratto',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                           >
                              <Contratto />
                           </RouteProtection>
                        ),
                        children: [
                           {
                              path: 'dati-generali',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <TabDatiGenerali />
                                 </RouteProtection>
                              ),
                           },
                           {
                              path: 'servizi',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <TabServizi />
                                 </RouteProtection>
                              ),
                           },
                           {
                              path: 'prezzi',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <TabPrezzi />
                                 </RouteProtection>
                              ),
                           },
                           {
                              path: 'compensi',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <TabCompensi />
                                 </RouteProtection>
                              ),
                           },
                           {
                              path: 'operatori-non-graditi',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <p>Operatori non graditi</p>
                                 </RouteProtection>
                              )
                           }
                        ]
                     },
                     {
                        path: 'contratti/:idContratto/servizi/:idServizio',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                           >
                              <MainServizio />
                           </RouteProtection>
                        ),
                        children: [
                           {
                              path: 'assegnazione-risorse',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <TabServizio />
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'operatori-assegnati',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <p>operatori assegnati</p>
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'costi',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <TabCosti />
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'dotazioni',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <p>dotazioni</p>
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'note-ed-allegati',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                                 >
                                    <TabNoteEdAllegati />
                                 </RouteProtection>
                              )
                           }
                        ]
                     },
                     {
                        path: 'contratti/:idContratto/servizi/:idServizio/giorno/:idGiorno/assegnazione-avanzata',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                           >
                              <AssegnazioneAvanzata />
                           </RouteProtection>
                        ),
                        children: []
                     },
                     {
                        path: 'contratti/:idContratto/servizi/:idServizio/giorno/:idGiorno/turno/:idTurno/assegnazione-avanzata',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                           >
                              <AssegnazioneAvanzata />
                           </RouteProtection>
                        ),
                        children: []
                     },
                     {
                        path: 'contratti/:idContratto/servizi/:idServizio/giorno/:idGiorno/consolidamento-avanzato',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                           >
                              <ConsolidamentoAvanzato />
                           </RouteProtection>
                        ),
                        children: []
                     },
                     {
                        path: 'contratti/:idContratto/servizi/:idServizio/giorno/:idGiorno/turno/:idTurno/consolidamento-avanzato',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_contratti']}
                           >
                              <ConsolidamentoAvanzato />
                           </RouteProtection>
                        ),
                        children: []
                     },
                     {
                        path: 'intermittenti',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin', 'erp_intermittenti']}
                           >
                              <PianificazioneIntermittenti />
                           </RouteProtection>
                        ),
                        children: []
                     },
                  ]
               }
            ]
         },
         {
            path: '*',
            element: (
               <p>Not found</p>
            )
         }
      ]
   }
}

export default PianificazioneRoutes;
