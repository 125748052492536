import { useState, useEffect } from 'react';

/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//MUI Components
import
{
   Box,
   IconButton,
   TextField
} from '@mui/material';

//Icons

import
{
   faAngleRight,
   faAngleLeft,
   faArrowRightToLine,
   faArrowLeftFromLine
} from '@fortawesome/pro-solid-svg-icons';
import { padding } from '@mui/system';

const TablePaginationActions = (props) =>
{

   const { count, page, rowsPerPage, onPageChange } = props;
   const [inputPage, setInputPage] = useState(page + 1);

   useEffect(() => {
      setInputPage(page + 1);
   }, [page]);

   const handlePageInputChange = (event) =>
   {
      const value = Number(event.target.value);
      setInputPage(value);
   };

   const handlePageInputBlur = () =>
   {
      let newPage = inputPage;
      if (newPage < 1) newPage = 1;
      else if (newPage > Math.ceil(count / rowsPerPage)) {
         newPage = Math.ceil(count / rowsPerPage);
      }

      onPageChange(null, newPage - 1);
   };

   const handleFirstPageButtonClick = (event) =>
   {
      onPageChange(event, 0);
   };

   const handleBackButtonClick = (event) =>
   {
      onPageChange(event, page - 1);
   };

   const handleNextButtonClick = (event) =>
   {
      onPageChange(event, page + 1);
   };

   const handleLastPageButtonClick = (event) =>
   {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
   };

   return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
         <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
         >
            <FontAwesomeIcon icon={faArrowLeftFromLine} />
         </IconButton>
         <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
         >
            <FontAwesomeIcon icon={faAngleLeft} />
         </IconButton>
         <TextField
            type="number"
            value={inputPage}
            onChange={handlePageInputChange}
            onBlur={handlePageInputBlur}
            onKeyUp={(e) => {
               if (e.key === 'Enter') {
                  handlePageInputBlur();
               }
            }}
            inputProps={{
               min: 1,
               max: Math.ceil(count / rowsPerPage),
               sx: {
                  textAlign: 'center',
                  paddingLeft: '.5rem',
                  paddingRight: '.5rem',
                  appearance: 'none',
                  'WebkitAppearance': 'none',
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                     display: 'none'
                  }
               }
            }}
            variant="outlined"
            size="small"
            sx={{
               width: 50
            }}
         />
         <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
         >
            <FontAwesomeIcon icon={faAngleRight} />
         </IconButton>
         <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
         >
            <FontAwesomeIcon icon={faArrowRightToLine} />
         </IconButton>
      </Box>
   )

}

export default TablePaginationActions;